export default {
  siteTitle: 'Get bonus',
  blogerName: 'AUGUSTO',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/c/SávioAugusto'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/augustoplaygo'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/augustandogamers'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/cf4e914d7',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/c1d069257',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c3e3e16f5',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c0f1ca247',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c5487cefc',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c9822b20e',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c25e81fb5',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cd9bc1018',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>AUGUSTO</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'AUGUSTO',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
